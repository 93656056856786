import React, { useState } from "react";
import { Link } from "react-router-dom";
import { checkFileExists, parseUrl } from "../api/Tizen";

const whichImageClass = (index) => (index % 4 === 1 || index % 4 === 2 ? 'image1' : 'image2');
// const whichSplit = (index) => (index % 2 === 0 ? 'left' : 'right');

const getTitleStyle = (titleLength) => {
  const screenWidth = window.innerWidth;

  if (screenWidth <= 480) {
    return { fontSize: '24px', lineHeight: '28px', zIndex: 1 };
  } else if (screenWidth <= 768) {
    return { fontSize: '28px', lineHeight: '32px', zIndex: 1 };
  } else if (screenWidth >= 1920) {
    if (titleLength <= 60) {
      return { fontSize: '48px', lineHeight: '52px', zIndex: 1 };
    } else if (titleLength <= 72) {
      return { fontSize: '42px', lineHeight: '48px', zIndex: 1 };
    } else if (titleLength <= 91) {
      return { fontSize: '36px', lineHeight: '40px', zIndex: 1 };
    } else {
      return { fontSize: '30px', lineHeight: '35px', zIndex: 1 };
    }
  } else {
    if (titleLength <= 60) {
      return { fontSize: '40px', lineHeight: '45px', zIndex: 1 };
    } else if (titleLength <= 72) {
      return { fontSize: '34px', lineHeight: '39px', zIndex: 1 };
    } else if (titleLength <= 91) {
      return { fontSize: '30px', lineHeight: '35px', zIndex: 1 };
    } else {
      return { fontSize: '25px', lineHeight: '30px', zIndex: 1 };
    }
  }
};


const Block = ({ index, title, path, disableFilter, showName, filtreColor, isFewCategories, onLoad, onError, imgPath, identifier, type }) => {
  const [loaded, setLoaded] = useState(false);
  const validFileExt = ["png", "jpg", "jpeg"];
  // const isMobile = window.innerWidth <= 480;
  const onImgError = (e) => {
    e.target.style.opacity = 0;
    onError();
  }
  const onImgLoad = (e) => {
    e.target.style.opacity = 1;
    setLoaded(true);
    onLoad();
  }
  // console.log("block identifier:", identifier);
  const urlData = parseUrl(imgPath, identifier, type);
  if (window.tizen && (urlData.extension === "mp4" || urlData.extension === "pdf")) {
    checkFileExists(urlData.file).then(fileExist => {
      if (!fileExist) {
        onError();
      }
    });
  }

  return (
    <div className={`split single-column left`} style={{ willChange: 'transform' }}>
      <div className={`container-image`}>
        <Link
          to={path}
          className={`block image ${whichImageClass(index)}`}
        >
          {(showName && !disableFilter) ? (
            <div style={{position: 'relative', borderRadius: 'inherit'}}>
              <div className="filtre" style={{ position: 'absolute', backgroundColor: filtreColor, zIndex: 1 }} />
              {(validFileExt.includes(urlData.extension.toLowerCase())) ? (
                <img src={imgPath} alt="thumbnail" onLoad={onImgLoad} onError={onImgError} className={`block image ${whichImageClass(index)}`} style={{ fontSize: 0, objectFit: "cover", opacity: loaded ? 1 : 0 }} />
              ) : (
                <img alt="thumbnail" className={`block image ${whichImageClass(index)}`} style={{ opacity: 0, fontSize: 0, objectFit: "cover" }} />
              )}
            </div>
          ) : (
            (validFileExt.includes(urlData.extension.toLowerCase()) ? (
              <img src={imgPath} alt="thumbnail" onLoad={onImgLoad} onError={onImgError} className={`block image ${whichImageClass(index)}`} style={{ fontSize: 0, objectFit: "cover", opacity: loaded ? 1 : 0 }} />
            ) : (
              <></>
            ))
            )}
          <div className="centered" style={isFewCategories ? {} : getTitleStyle(title.length)}>
            {showName && title}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Block;
