import { faAngleLeft, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PdfReader from "../components/PdfReader";
import APIURL from "../api/dns";
import OVHAUTH from "../token-ovh/ovh-auth";
import ImageReader from "../components/ImageReader";
import VideoReader from "../components/VideoReader";
import SiteReader from "../components/SiteReader";
import { TV_localStoragePath, parseUrl } from '../api/Tizen';
import { useNavigate } from "react-router-dom";


const Product = () => {
    const navigate = useNavigate();

    const param = useParams();
    // const token = localStorage.getItem('token');
    //const navigate = useNavigate();

    const [product, setProduct] = useState({});
    const [parentId, setParentId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    //const [category, setCategory] = useState({});
    //const [currentIndex, setCurrentIndex] = useState(0);
    //const [loading, setLoading] = useState(true);

    const apiURL = APIURL + '/api/product/' + param.productId;
    const fetchProducts = useCallback(async () => {
        const reqOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Device-Code": localStorage.getItem('Device-Code'),
            "Device-UUID": localStorage.getItem('Device-UUID')
          }
        }
        fetch(apiURL, reqOptions)
            .then(async (resp) => {
                let data = await resp.json();
                const urlData = parseUrl(data.file, data.id, 'p');
                data.qr_file = urlData.url;
                if (window.tizen )
                    data.file = `${TV_localStoragePath}${urlData.file}`;
                setProduct(data);
                setParentId(data.category);
            }).catch((error) => console.error('Error fetching products:', error));
    }, [apiURL])

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    useEffect(() => {
        if (product?.file_type === 'url' && product?.url) {
            window.open(product.url, '_blank');
            navigate(-1); // Simule "retour arrière"
        }
    }, [product, navigate]);



    /*const fetchCategory = async () => {
      try {
          const response = await fetch(APIURL + '/api/category/' + param.categoryId, reqOptions);
          const data = await response.json();
          console.log('Category:', data);

          setCategory(data);
          setParentId(data.parent);

          if (currentIndex >= 0 && currentIndex < data.products.length) {
              setProduct(data.products[currentIndex]);
          }
      } catch (error) {
          console.error('Error fetching category:', error);
      } finally {
          setLoading(false); // Indiquer que le chargement est terminé
      }
  };

  useEffect(() => {
    if (param.categoryId) {
        setLoading(true); // Indiquer que le chargement commence
        fetchCategory();
    }
}, [currentIndex, param.categoryId]);

    const fetchProductById = async (productId) => {
      try {
          const response = await fetch(APIURL + '/api/product/' + productId, reqOptions);
          const data = await response.json();
          setProduct(data);
      } catch (error) {
          console.error('Error fetching product by ID:', error);
      }
  };

  console.log('Product:', product);
  console.log('Category:', category);

  const handleNavigation = (direction) => {
    const newIndex = direction === 'left' ? currentIndex - 1 : currentIndex + 1;

    if (category.products && newIndex >= 0 && newIndex < category.products.length) {
        const productAtIndex = category.products[newIndex];
        const productId = productAtIndex.id;
        const newPath = `/category/${param.categoryId}/product/${productId}`;

        fetchProductById(productId);
        navigate(OVHAUTH + newPath);
        setCurrentIndex(newIndex);
    } else {
        console.log('Invalid newIndex:', newIndex);
    }
};*/



    if (product.file_type === 'application/pdf') {
        if( showModal === false){
            setShowModal(true);
        }
        return (
            <>
                <Link to={parentId == null ? OVHAUTH + '/home' : OVHAUTH + '/category/' + parentId + '?stats=ignore'} className='goBack'>
                    <img src="/img/back.png" alt="Go Back" style={{ width: '100%', height: '100%' }} />
                </Link>
                <Link to={OVHAUTH + "/home"} className='goHome'>
                    <img src="/img/home.png" alt="Home" style={{ width: '100%', height: '100%' }} />
                </Link>

                <PdfReader downloadable={product.downloadable} url={product.qr_file} />
            </>
        );
    }
    if (product.file_type === 'image/jpeg' || product.file_type === 'image/png') {
        return (
          <>
              <Link to={parentId == null ? OVHAUTH + '/home' : OVHAUTH + '/category/' + parentId + '?stats=ignore'} className='goBack'>
                  <img src="/img/back.png" alt="Go Back" style={{ width: '100%', height: '100%' }} />
              </Link>
              <Link to={OVHAUTH + "/home"} className='goHome'>
                  <img src="/img/home.png" alt="Home" style={{ width: '100%', height: '100%' }} />
              </Link>

            {/*<button onClick={() => handleNavigation('left')} className='goLeft'>
                <FontAwesomeIcon icon={faAngleLeft} fontSize='48px' color='white' />
            </button>*/}

            <div className="content-box" style={{position: "fixed", top: 0, width: '100%'}}>
              <img className="media-content" src={product.file} alt='media'/>
            </div>

            {/*<button onClick={() => handleNavigation('right')} className='goRight'>
                <FontAwesomeIcon icon={faAngleRight} fontSize='48px' color='white' />
            </button>*/}
          </>
        );
      }
    if (product.file_type === 'video/mp4') {
        return (
            <>
                <Link to={parentId == null ? OVHAUTH + '/home' : OVHAUTH + '/category/' + parentId + '?stats=ignore'} className='goBack'>
                    <img src="/img/back.png" alt="Go Back" style={{ width: '100%', height: '100%' }} />
                </Link>
                <Link to={OVHAUTH + "/home"} className='goHome'>
                    <img src="/img/home.png" alt="Home" style={{ width: '100%', height: '100%' }} />
                </Link>

                {/*<button onClick={() => handleNavigation('left')} className='goLeft'>
                    <FontAwesomeIcon icon={faAngleLeft} fontSize='48px' color='white' />
                </button>*/}


                <div className="content-box" style={{position: "fixed", top: 0, width: "100%" }}>
                    {/*<video className="media-content" src={product.file} muted autoPlay />*/}

                    <video className="player-wrapper" src={product.file} style={{width:'100%',height:'100%'}} autoPlay muted loop>
                    </video>
                </div>

                {/*<button onClick={() => handleNavigation('right')} className='goRight'>
                    <FontAwesomeIcon icon={faAngleRight} fontSize='48px' color='white' />
                </button>*/}
            </>
        );
    }
    if (product.file_type === 'url') {
        return (
            <>
                <Link to={parentId == null ? OVHAUTH + '/home' : OVHAUTH + '/category/' + parentId + '?stats=ignore'} className='goBack'>
                    <img src="/img/back.png" alt="Go Back" style={{ width: '100%', height: '100%' }} />
                </Link>
                <Link to={OVHAUTH + "/home"} className='goHome'>
                    <img src="/img/home.png" alt="Home" style={{ width: '100%', height: '100%' }} />
                </Link>

            </>
        );
    }
}

export default Product;
