import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Product from './views/Product';
import Login from './views/Login';
import { useEffect, useState } from 'react';
import { Detector } from 'react-detect-offline';
import AuthError from './views/AuthError';
import Home from './views/Home';
import Page from './views/Page';
import OVHAUTH from './token-ovh/ovh-auth';
import APIURL from "./api/dns";
import LoadingScreen from "./components/LoadingScreen"; // Import your loading spinner component
import { getTizenVersion } from './api/Tizen.jsx';



function App() {
  const navigate = useNavigate();
  const loc = useLocation().pathname;

  //pour events
  const [isActive, setIsActive] = useState(true);
  // Screensaver
  const [triggerCooldown, setTriggerCooldown] = useState(0);

  // For PMR button
  const [divPMR, setDivPMR] = useState(false);
  const [divBtn, setDivBtn] = useState(true);
  const [divBtnPMR, setDivBtnPMR] = useState(true);
  const [divBtnStandard, setDivBtnStandard] = useState(false);

  const [marginSize, setMarginSize] = useState('');
  const [verionPMR, setVersionPMR] = useState('Version PMR');
  const [versionStandard, setVersionStandard] = useState('Version Standard');
  // END PMR button

  // QRCode for iframe
  const [QRCodeURL, setQRCodeURL] = useState('');
  const apiURL = APIURL +'/api/device/';

  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const CODE = localStorage.getItem('Device-Code');
  const TOKEN = localStorage.getItem('Device-Token');
  const URL_TOKEN = localStorage.getItem('Url-Token');
  useEffect(() => {
    if (window.screen.orientation.type == "portrait-primary") {
      setDivBtn(true);
    } else if (window.screen.orientation.type == "landscape-primary") {
      setDivBtn(true);
    } else {
      setDivBtn(false);
    }
    try {
      const productInfo = window.webapis.productinfo;
      const version = productInfo.getVersion();

      const duid = productInfo.getDuid();
      // Activate below for PROD
      localStorage.setItem('Device-UUID', duid);

      // Activate below for DEV
      //localStorage.setItem('Device-UUID', 'brrr');

    } catch (err) {
      //console.error('ERROR: ', err);
    }
    // fetchSettings();

  }, []);

  const isNull = (object) => {
    if (object == null || object == undefined)
      return true;
    else {
      if (object.length == 0)
        return true;
      else
        return false;
    }
  }

  const showWidgets = () => {
    if (isNull(CODE) || loc.includes('product'))
      return false;
    return true;
  }

  // // Le code ci-dessous va essayer de créer le dossier 'assets' dans le stoquage de l'écran si celui-ci n'existe pas
  // // afin de stoquer les médias.
  // const createDirectory = async (dirPath, rootPath) => {
  //   try {
  //     // Request access to the filesystem
  //     const fileSystem = window.tizen.filesystem;
  //     const dir = await fileSystem.resolve(rootPath, (dir) => {
  //       dir.createDirectory(dirPath);
  //     }, (error) => {
  //       console.error('Failed to resolve directory: ' + error.message);
  //     });

  //     try {
  //       const newDirectory = await dir.createDirectory(dirPath);
  //       console.log('Directory created:', newDirectory.toURI());
  //     } catch (error) {
  //       console.warn("Could not create directory: " + error)
  //     }
  //   } catch (error) {
  //     console.error('Error creating directory:', error);
  //   }
  // };
  // if (!window.tizen) {
  //   console.warn("Tizen API is unavailable.");
  // } else {
  //   createDirectory("assets", "images");
  // }
  // //

  //ACTIVER LE CODE CI-DESSOUS POOUR LES ECRANS SEULEMENT
  //A DESACTIVER POUR LA PARTIE IFRAME

  // useEffect(() => {
  //   if (isNull(URL_TOKEN) && isNull(CODE)) {
  //     navigate(OVHAUTH + '/');
  //   } else if ((!isNull(URL_TOKEN) || (!isNull(CODE)))) {
  //     // Simulate a 5-second loading delay
  //     const loadingTimeout = setTimeout(() => {
  //       navigate(OVHAUTH + '/home'); // Navigate to '/home' after the loading delay
  //     }, 5000); // 5000 milliseconds = 5 seconds
  //
  //     // Clean up the timeout when the component unmounts
  //     return () => clearTimeout(loadingTimeout);
  //   }
  // }, []);

  /// Show and hide the div of PMR button and change the message in the button (suitable for portraits and landscapes)
  const changeStyle = () => {
    if (divPMR == false) {
      setDivPMR(true);
      setDivBtnPMR(false);
      setDivBtnStandard(true);
      if (window.screen.orientation.type == "portrait-primary") {
        setMarginSize('70');
      }
      if (window.screen.orientation.type == "landscape-primary") {
        setMarginSize('30');
      }
    } else {
      setDivBtnPMR(true);
      setDivBtnStandard(false);
      setDivPMR(false);
    }
  };
  /// END

  // Simulated job function with a 5-second delay
  const jobFunction = () => {
      return new Promise((resolve) => {
          setTimeout(() => {
              resolve(); // Resolve the promise after 5 seconds
          }, 5000); // 5000 milliseconds = 5 seconds
      });
  };

  useEffect(() => {
    if (window.tizen)
      console.log("Running Tizen version", getTizenVersion());
  }, []);

  return (
      <>
        {isLoading ? (
            <LoadingScreen performJob={jobFunction} onFinishLoading={() => setIsLoading(false)} loadingText={"CHARGEMENT EN COURS..."}/> // Use the LoadingScreen component with 5-second delay
        ) : (
            <>
              {isNull(URL_TOKEN)?
                  <>
                    {divPMR ?
                        <div style={{ marginTop: `${marginSize}%` }}></div> : <div></div>
                    }

                    {divBtn &&
                    <div className="mybutton btnPMR" style={{zIndex:4}} onClick={changeStyle}>
                      <a className="feedback button-shop" target="_blank">
                        {divBtnPMR && <div> {verionPMR} </div>}
                        {divBtnStandard && <div> {versionStandard} </div>}
                      </a>
                    </div>
                    }
                  </>
                  :
                  null
              }

              <Detector
                  render={({ online }) =>
                      <div className={online ? "" : "connexion-banner"}>
                        {online ? "" : "Déconnecté. Vérifiez votre connexion internet."}
                      </div>
                  }
              />

              <Routes>
                <Route path={OVHAUTH + "/"} element={<Login setQRCodeURL={setQRCodeURL} />} />
                <Route path={OVHAUTH + "/error"} element={<AuthError />} />
                <Route path={OVHAUTH + "/home"} element={<Home QRCodeURL={QRCodeURL} setIsActive={setIsActive} isActive={isActive} setQRCodeURL={setQRCodeURL} setTriggerCooldown={setTriggerCooldown} />} />
                <Route path={OVHAUTH + "/category/:categoryId"} element={<Page setIsActive={setIsActive} isActive={isActive} />} />
                <Route path={OVHAUTH + "/product/:productId"} element={<Product setIsActive={setIsActive} isActive={isActive}/>} />
              </Routes>
            </>
        )}
      </>
  );
}

export default App;
