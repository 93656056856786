import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners';

const LoadingScreen = ({ performJob, onFinishLoading, loadingText }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [finish, setFinish] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (performJob) await performJob();
                setFinish(true);
            } catch (error) {
                console.error('Error while performing job:', error);
                setIsLoading(false);
                onFinishLoading();
            }
        };

        fetchData();
        return () => {};
    }, [performJob, onFinishLoading]);

    useEffect(() => {
        let timeout;
        if (finish) {
            timeout = setTimeout(() => {
                setIsLoading(false);
                onFinishLoading();
            }, 2000);
        }

        return () => clearTimeout(timeout);
    }, [isLoading, finish]);

    const override = css`
      display: block;
      margin: 0 auto;
    `;

    const loadingScreenStyles = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '100vh',
        zIndex: 1000,
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        top: 0,
        overflow: 'hidden',
        padding: '20px',
    };

    const textStyle = {
        marginTop: '5vh',
        fontSize: 'clamp(1rem, 2.5vw, 2rem)', // min 1rem, max 2rem, adaptatif selon largeur écran
        maxWidth: '90%',
        textAlign: 'center',
        lineHeight: '1.4',
        padding: '0 10px',
        wordBreak: 'break-word',
    };


    // Taille responsive du RingLoader
    const getLoaderSize = () => {
        if (window.innerWidth < 480) return 60; // Mobile
        if (window.innerWidth < 768) return 90; // Tablette
        return 150; // Desktop
    };

    const [loaderSize, setLoaderSize] = useState(getLoaderSize());

    useEffect(() => {
        const handleResize = () => {
            setLoaderSize(getLoaderSize());
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div style={loadingScreenStyles} className="loading-screen">
            {isLoading && (
                <>
                    <div className="spinner">
                        <RingLoader
                            css={override}
                            size={loaderSize}
                            color={'#000000'}
                            loading={isLoading}
                        />
                    </div>
                    <p style={textStyle}>{loadingText}</p>
                </>
            )}
        </div>
    );
};

export default LoadingScreen;
