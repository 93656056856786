import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Block from '../components/Block';
import Events from '../components/Events';
import APIURL from '../api/dns';
import OVHAUTH from '../token-ovh/ovh-auth';
import QRCode from 'react-qr-code';
import { Modal } from 'react-bootstrap';
import { fetchThumbnail, writeToStorage, parseUrl, TV_localStoragePath } from '../api/Tizen';
import LoadingScreen from '../components/LoadingScreen';

const Home = (props) => {
    const [indexedCatalog, setIndexedCatalog] = useState([]);
    const [showQRCodeModal, setShowQRCodeModal] = useState(false);
    const TOKEN = localStorage.getItem('Device-Token');
    const URL_TOKEN = localStorage.getItem('Url-Token');
    const isTokenAuthenticated = URL_TOKEN;
    const [loading, setLoading] = useState(true);
    const [fetchError, setFetchError] = useState(null);
    const [retryCountdown, setRetryCountdown] = useState(5);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [isFetching, setIsFetching] = useState(false);
    const [retryTimeoutId, setRetryTimeoutId] = useState(null);
    const handleClose = () => setShowQRCodeModal(false);
    const handleShow = () => setShowQRCodeModal(true);
    const [dataFetched, setDataFetched] = useState(false);
    const [accountInUseError, setAccountInUseError] = useState(false);
    const [redirectCountdown, setRedirectCountdown] = useState(10);
    const navigate = useNavigate();
    const [filtreColor, setFiltreColor] = useState("");
    const [blockBackgroundPath, setBlockBackgroundPath] = useState([]);
    const [jobCount, setJobCount] = useState(0);
    // const isFewCategories = indexedCatalog.length <= 3;
    // const rowClass = isFewCategories ? 'single-column-row' : 'row';

    const startRedirectCountdown = () => {
        let countdown = redirectCountdown;
        const countdownIntervalId = setInterval(() => {
            countdown--;
            if (countdown <= 0) {
                clearInterval(countdownIntervalId);
                setRedirectCountdown(10);
                navigate(OVHAUTH+ '/')
            } else {
                setRedirectCountdown(countdown);
            }
        }, 1000);
    };

    const fetchSettings = async () => {
        const CODE = localStorage.getItem('Device-Code');
        const apiUrl = `${APIURL}/api/auth/organization/settings`;

        try {
            const response = await fetch(apiUrl, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Device-Code": CODE,
                    "Device-UUID": localStorage.getItem('Device-UUID')
                }
            });
            const data = await response.json();
            if (response.ok && data[0]?.color) {
                setFiltreColor(data[0].color);
                props.setTriggerCooldown(data[0].trigger_cooldown);
            }
        } catch (error) {
            console.error('Error fetching settings:', error);
        }
    };

    const fetchCategoriesAndProducts = async () => {
        try {
            // Check if Code-Device and UUID are available
            const deviceCode = localStorage.getItem('Device-Code');
            const deviceUUID = localStorage.getItem('Device-UUID');
            if (!deviceCode || !deviceUUID) {
            // Handle missing Code-Device or UUID
                setAccountInUseError(true);
                startRedirectCountdown();
                return;
            }

            const response = await fetch(`${APIURL}/api/catalog/sorting/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Device-Code": deviceCode,
                    "Device-UUID": deviceUUID
                }
            });

            // Check if the response indicates that the account is used by another user
            if (response.status === 403 || response.status === 404) {
                // Handle account in use or missing Code-Device
                setAccountInUseError(true);
                startRedirectCountdown();
                return;
            }

            const data = await response.json();
            const sortedCatalog = [...data.catalog];
            sortedCatalog.sort((a, b) => a.position - b.position);
            setIndexedCatalog(sortedCatalog);
            const blockBckgPath = [];
            sortedCatalog.forEach(block => {
                if (block.category) {
                    let urlData = parseUrl(block.category.thumbnail ? block.category.thumbnail : block.category.file, block.category.id, 'c');
                    if (window.tizen ) {
                        urlData.url = `${TV_localStoragePath}${urlData.file}`;
                    }
                    blockBckgPath.push(urlData.url);
                } else {
                    let urlData = parseUrl(block.product.thumbnail ? block.product.thumbnail : block.product.file, block.product.id, 'p');
                    if (window.tizen ) {
                        urlData.url = `${TV_localStoragePath}${urlData.file}`;
                    }
                    blockBckgPath.push(urlData.url);
                }
            });
            setBlockBackgroundPath(blockBckgPath);
            //setProductList(data.products.reverse());
            // const productBckgPaths = [];
            // data.products.forEach(product => {
            //     let urlData = parseUrl(product.thumbnail ? product.thumbnail : product.file, product.id, 'p');
            //     if (window.tizen ) {
            //         urlData.url = `${TV_localStoragePath}${urlData.file}`;
            //     }
            //     productBckgPaths.push(urlData.url);
            // });
            // setProductsBckgPaths(productBckgPaths);
            setFetchError(null);
            setDataFetched(true);
        } catch (error) {
            console.error('Error fetching data', error);
            setFetchError('Erreur lors du chargement des données');
        } finally {
            setLoading(false); // Set loading to false regardless of success or failure
            setIsFetching(false);
        }
    };

    const retryFetch = () => {
        if (navigator.onLine) {
            setIsFetching(true);
            fetchCategoriesAndProducts();
        } else {
            let retryCounter = retryCountdown;

            const intervalId = setInterval(() => {
                retryCounter--;

                if (retryCounter <= 0) {
                    clearInterval(intervalId);
                    setRetryCountdown(5); // Réinitialiser le compteur
                    setIsFetching(true); // Déclencher une nouvelle tentative de fetch
                    fetchCategoriesAndProducts();
                } else {
                    setRetryCountdown(retryCounter); // Mettre à jour le compteur
                }
            }, 1000);

            setRetryTimeoutId(intervalId);
        }
    };

    const clearRetryTimeout = () => {
        if (retryTimeoutId) {
            clearTimeout(retryTimeoutId);
            setRetryTimeoutId(null);
        }
    };

    const imageLoadSuccess = (event, data) => {
        // In case the image loaded successfully, do something?
    }

    const imageLoadFailed = async (event, index, data, isCategory, identifier, type) => {
        const url = data.thumbnail ? data.thumbnail : data.file
        if (window.tizen ) {
            setJobCount(prevCount => prevCount + 1);
            const urlData = await parseUrl(url, identifier, type);
            let data = await fetchThumbnail(url);
            try {
                await writeToStorage(urlData.file, data, () => {
                    setJobCount(prevCount => prevCount - 1); // Decrement jobCount once storage write is completed to disable loading screen
                });
            } catch (e) {
                console.error("Failed to write to device storage.");
                setJobCount(prevCount => prevCount - 1);
            }
        }
        setBlockBackgroundPath(prevPaths => {
            const updatedPaths = [...prevPaths];
            updatedPaths[index] = url; // Image path is always under the Images folder;
            return updatedPaths;
        });
        //  else {
        //     setProductsBckgPaths(prevPaths => {
        //         const updatedPaths = [...prevPaths];
        //         updatedPaths[index] = url; // Image path is always under the Images folder;
        //         return updatedPaths;
        //     });
        // }
    }

    useEffect(() => {
        fetchCategoriesAndProducts();
        fetchSettings();
        const handleConnectionChange = () => {
            setIsOnline(navigator.onLine);

            if (!navigator.onLine) {
                setRetryCountdown(5);
                clearRetryTimeout();
            } else if (!isFetching && retryCountdown === 0) {
                setIsFetching(true);
                setRetryCountdown(0);
            }
        };

        window.addEventListener("online", handleConnectionChange);
        window.addEventListener("offline", handleConnectionChange);

        return () => {
            window.removeEventListener("online", handleConnectionChange);
            window.removeEventListener("offline", handleConnectionChange);
            clearRetryTimeout(); // Cleanup the retry timeout when the component unmounts
        };
    }, [isFetching]);

    useEffect(() => {
        if (!isOnline && retryCountdown === 5 && !isFetching) {
            retryFetch();
        }
    }, [isOnline, retryCountdown, isFetching]);

    useEffect(() => {
        // For subsequent renders, update the QR code URL
        updateQRCodeURL();
    }, []);

    const updateQRCodeURL = () => {
        if (!URL_TOKEN) {
            props.setQRCodeURL(`https://iframe.multiapp.fr?token=${TOKEN}`);
        }
    };

    const handleReloadData = () => {
        setFetchError(null);
        setIsFetching(true);
        fetchCategoriesAndProducts();
    };


    // For the error state
    if (fetchError) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', fontSize: '25px' }}>
                {fetchError} <span style={{ marginTop: '20px', fontSize: '16px' }}>Nouvelle tentative de reconnexion dans {retryCountdown} seconde.s...</span>
            </div>
        );
    }

    // Check for the account in use error
    if (accountInUseError) {
        // Display a message before redirecting
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', fontSize: '25px' }}>
                <h3>Erreur Code-Device/UUID</h3>
                Redirection en cours vers l'accueil dans {redirectCountdown} seconde.s...
            </div>
        );
    }

    // Check if no data is found
    if (indexedCatalog.length === 0 && dataFetched) {
        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', fontSize: '25px' }}>
                    <h1>Aucune donnée sur ce compte.</h1> Veuillez vérifier votre catalogue.
                    <button className="error-button" onClick={handleReloadData}>Recharger</button>
                    {/* <button className="error-button" onClick={handleLogout}>Se déconnecter</button>*/}
                </div>
            </>
        );
    }

    // Used when data is being written to the device storage. Simulate tasks to keep the loadingScreen alive.
    const performJob = async (jobCount) => {
        while (jobCount > 0) {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    };

    return (
        <>
            {jobCount > 0 ? (
                <LoadingScreen performJob={() => performJob(jobCount)} onFinishLoading={()=>{console.log("Medias stored to device storage.")}} loadingText={`INSTALLATION DES MEDIAS DE LA PAGE...`}/>
                ) : (
                    <>

                            {indexedCatalog.length > 0 ? (
                                <div className="events">
                                    <Events setIsActive={props.setIsActive} isActive={props.isActive} writeJob={() => {setJobCount(prevCount => prevCount - 1);}}/>
                                </div>
                            ) : null}


                    <div className="row">
                        {indexedCatalog.map((block, index) => {
                            if (block.category) {
                                return <Block key={index} index={index} showName={block.category.show_name} title={block.category.name} path={OVHAUTH + "/category/" + block.category.id} disableFilter={true} onLoad={(event) => imageLoadSuccess(event)} onError={(event) => imageLoadFailed(event, index, block.category, true, block.category.id, 'c')} imgPath={blockBackgroundPath[index]} identifier={block.category.id} type={'c'}/>
                            } else {
                                return <Block key={index} index={index} showName={block.product.show_name} downloadable={block.product.downloadable} title={block.product.name} path={OVHAUTH + "/product/" + block.product.id} filtreColor={filtreColor} onLoad={(event) => imageLoadSuccess(event)} onError={(event) => imageLoadFailed(event, index, block.product, false, block.product.id, 'p')} imgPath={blockBackgroundPath[index]} identifier={block.product.id} type={'p'}/>
                            }
                        })}
                    </div>

                    {isTokenAuthenticated ? null : (
                        <>
                            {indexedCatalog.length > 0 ? (
                                <div className="mybutton-QR btnPMR" style={{ zIndex: 4 }}>
                                    <a className="feedback" style={{ backgroundColor: "#343434", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={handleShow}>
                                        <QRCode value={props.QRCodeURL} size={50}></QRCode>
                                    </a>
                                </div>
                            ) : null}

                            <Modal show={showQRCodeModal} onHide={handleClose} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title className="centerModalTitle">Scannez moi</Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <div className="qr-container">
                                        <QRCode value={props.QRCodeURL}></QRCode>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default Home;
